import axios from "axios";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import router from '@/router'

axios.interceptors.request.use(request => {
  const token = Cookies.get('token')
  if (token && request && request.headers) {
    request.headers['Authorization'] = `Token ${token}`
  }
  // request.baseURL = process.env.NODE_ENV !== 'production' ? 'http://5.145.160.168:10002' : 'http://5.145.160.168:10000'
  request.baseURL = 'https://kassa67.ru'
  return request
})


axios.interceptors.response.use(response => response,async error => {
  if (error.response.status === 401) {
    await Swal.fire({
      icon: 'warning',
      title: 'Авторизация',
      text: 'Нужно заново авторизоваться',
      confirmButtonText: 'Авторизоваться'
    })
    await router.push({name: 'login'})
  }
  return  Promise.reject(error)
})