<template>
  <v-main>
    <v-slide-x-transition mode="out-in" >
      <router-view />
    </v-slide-x-transition>
  </v-main>
</template>

<script>
export default {
  name: "Login"
}
</script>

<style scoped>

</style>