



































import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class AdminMiddleware extends Vue {
  navigationLinks = [
    {icon: 'mdi-account', name: 'Клиенты', routeName: 'admin.clients'},
  ]
}
