import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/src/locale/ru';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru',
  },
  theme: {
    themes: {
      light: {
        primary: '#4cb853', // #E53935
        info: '#28aa92',
        warning: '#f9961e',
        error: '#ff1f27',
      },
    },
  },
});
