








import Vue from 'vue';
import Cookies from "js-cookie";

Cookies.set('SameSite', 'None')

export default Vue.extend({
  name: 'App',

  data: () => ({
    middleware: 'Clients'
  }),
  watch: {
    '$route.name' () {
      if (this.$route.meta) {
        this.middleware = this.$route.meta.middleware
      } else {
        this.middleware = 'Default'
      }
    }
  }
});
