




















import Vue from 'vue'
import Component from 'vue-class-component'
import Cookies from "js-cookie";

@Component
export default class CabinetView extends Vue{
  logout(): void {
    Cookies.remove('token')
    this.$router.push({name: 'login'})
  }
}
