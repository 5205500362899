import Vue from 'vue'
import Vuex from 'vuex'
import { getUserProfile } from "@/api/profile";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAdmin: false,
  },
  mutations: {
    changeIsAdmin(store, value: boolean) {
      store.isAdmin = value
    }
  },
  actions: {
    async fetchIsAdmin(): Promise<void> {
      const profile = await getUserProfile()
      this.commit('changeIsAdmin', profile.isAdmin)
    }
  },
  modules: {
  }
})
