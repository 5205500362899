<template>
  <div>
    <v-toolbar color="primary" dark>
      <v-spacer/>
      <v-toolbar-title>Клиенты</v-toolbar-title>
      <v-spacer/>
    </v-toolbar>
    <v-main>
      <router-view/>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "Clients"
}
</script>

<style scoped>

</style>