import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/vuelidate'
import './plugins/axios'

import Clients from './middleware/Clients.vue'
import Login from './middleware/Login.vue'
import ClientCabinet from './middleware/Cabinet.vue'
import Admin from './middleware/Admin.vue'

import {formatPrice} from "@/filters/format-price";

Vue.config.productionTip = false
Vue.component('Clients', Clients)
Vue.component('Login', Login)
Vue.component('Cabinet', ClientCabinet)
Vue.component('Admin', Admin)

Vue.filter('price', formatPrice)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
