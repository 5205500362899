import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

const LoginView = () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
const ClientCabinetView = () => import(/* webpackChunkName: "client" */ '../views/ClientCabinet.vue')
const RegistrationView = () => import(/* webpackChunkName: "client" */ '../views/Registration.vue')

// admin panel
const AdminPanelIndexView = () => import(/* webpackChunkName: 'admin-panel' */ '../views/admin-panel/Index.vue')
const AdminPanelClientsView = () => import(/* webpackChunkName: 'admin-panel' */ '../views/admin-panel/Clients.vue')

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/admin',
    component: AdminPanelIndexView,
    children: [
      {
        path: 'clients',
        name: 'admin.clients',
        component: AdminPanelClientsView,
        meta: {
          middleware: 'Admin'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      middleware: 'Login'
    }
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationView,
    meta: {
      middleware: 'Login'
    }
  },
  {
    path: '/cabinet',
    name: 'cabinet',
    component: ClientCabinetView,
    meta: {
      middleware: 'Cabinet'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
